<template>
  <b-modal
    id="registration-modal"
    ref="registration-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hide="removeDisableCopy"
    @shown="shown"
    @hidden="reset"
  >
    <template v-slot:default>

      <!-- REGISTRATION FORM -->
      <div v-if="localStep === 'registration'">
        <div class="text-center">
          <h2 class="highlighted-color1">
            <span>{{ $t('registration') }}</span>
          </h2>
          <p>{{ $t('enter_your_details_below') }}</p>
        </div>
        <AlertWraper
          :content="content"
          :type="content_type"
        />
        <form @submit.prevent="submit" class="default-form" ref="authForm">
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('full_name') }}*</label>
            <input type="text" class="form-control" :placeholder="$t('full_name')" v-model.trim="$v.form.name.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('email') }}*</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('confirm_email') }}*</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email_confirmation.$model" />
          </div>
          <!-- MESSAGES -->
          <div
            class="mb-2 error-message-card text-white font-size-small px-3 py-2"
            v-if="!isEmailInputConfirmed && form.email && form.email_confirmation"
          >
            <p
              class="mb-0"
            >
              {{ $t('email_addresses_must_match') }}
            </p>
          </div> <!-- END MESSAGES -->
          <div class="form-group mb-3 mt-3">
            <label class="form-label fw-bold">{{ $t('country') }}*</label>
            <div class="custom-select">
              <select class="form-control" v-model.trim="$v.form.country.$model">
                <option disabled selected value="">{{ $t('select') }}</option>
                <option v-for="(country, index) in parsedCountries" :key="index" :value="country.code">{{ country.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('phone') }}*</label>
            <PhoneInput
              v-model.trim="$v.form.phone.$model"
              :country="form.country"
            />
          </div>
          <div class="form-group mb-3" v-if="1 == 2">
            <label class="form-label fw-bold">{{ $t('indication_code') }}</label>
            <input type="text" class="form-control" placeholder="" v-model.trim="form.referral_code" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('password') }}*</label>
            <input type="password" class="form-control" :placeholder="$t('enter_your_password')" v-model.trim="$v.form.password.$model" autocomplete="new-password" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('confirm_password') }}*</label>
            <input type="password" class="form-control" :placeholder="$t('confirm_password')" v-model.trim="$v.form.password_confirmation.$model" autocomplete="new-password" />
          </div>
          <div class="form-group mb-3">
            <div class="custom-select">
              <label class="form-label fw-bold">{{ $t('language') }}*</label>
              <select class="form-control" v-model.trim="$v.form.language.$model">
                <option disabled="true" selected="true" value="">{{ $t('select') }}</option>
                <option v-for="(language, index) in availableLanguages" :key="index" :value="language">{{ $t('languages.' + language) }}</option>
              </select>
            </div>
          </div>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model.trim="$v.form.terms.$model" id="terms">
              <label class="form-check-label" for="terms">{{ $t('i_have_read_and_agree_to_the') }} <router-link :to="{ name: 'statics.terms' }" target="_blank">{{ $t('terms_of_use_and_privacy_policy') }}</router-link>.</label>
            </div>
          </div>

          <div class="text-center mt-3">
            <Button
              type="submit"
              class="btn btn-primary"
              :disabled="$v.$invalid || !isEmailInputConfirmed"
              :loading="loader"
            >
              {{ $t('register') }}
            </Button>
          </div>
        </form>
      </div> <!-- END REGISTRATION FORM -->

      <!-- CONFIRMATION FORM -->
      <div v-if="localStep === 'verification'">
        <div class="text-center">
          <h2>{{ $t('confirmation') }}</h2>
          <p>
            {{ $t('we_send_a_code_to') }}<br/>
            <strong>{{ confirmationEmail }}</strong>
          </p>
        </div>
        <AlertWraper
          :content="content"
          :type="content_type"
        />
        <div class="mt-3">
          <form @submit.prevent="verify" class="text-center default-form">
            <div class="form-group">
              <label class="form-label fw-bold">{{ $t('enter_the_code_sent') }}</label>
              <input type="text" class="form-control" v-model.trim.trim="$v.confirmation.code.$model" placeholder="" />
            </div>
            <p class="font-size-small mt-2 col-lg-10 mx-lg-auto">{{ $t('code_may_be_in_your_spam_folder') }}</p>
            <div class="text-center mt-3">
              <a href="#" @click.prevent="reSendCode" class="mb-3 d-block">
                <template v-if="isResendingCode">
                  <Loader
                    :size="'small'"
                    :align="'start'"
                  />
                </template>
                <template v-else>
                  {{ $t('didnt_receive_it') }} <br>{{ $t('send_code_again') }}
                </template>
              </a>
              <Button
                type="submit"
                class="btn btn-primary"
                :disabled="$v.$invalid"
                :loading="loader"
              >
                {{ $t('confirm') }}
              </Button>
            </div>
          </form>
        </div>
      </div> <!-- END CONFIRMATION FORM -->
    </template>

  </b-modal>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { parseErrorResponseToArray } from '@/utils'
import { required, email } from 'vuelidate/lib/validators'
import variables from '@/mixins/variables'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import PhoneInput from '@/components/common/Input/PhoneInput'

export default {
  name: 'RegistrationModal',
  mixins: [variables],
  props: ['step', 'userEmail'],
  components: {
    AlertWraper,
    Loader,
    Button,
    PhoneInput
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      errors: null,
      loader: false,
      localStep: 'registration',
      confirmation: {
        code: '',
        email: ''
      },
      isResendingCode: false,
      form: {
        name: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        phone: '',
        language: '',
        country: '',
        terms: ''
      }
    }
  },
  validations () {
    if (this.localStep === 'registration') {
      const obj = {
        form: {
          name: { required },
          email: { required, email },
          email_confirmation: { required, email },
          password: { required },
          password_confirmation: { required },
          phone: { required },
          language: { required },
          country: { required },
          terms: { required }
        }
      }
      return obj
    } else if (this.localStep === 'verification') {
      return {
        confirmation: {
          code: { required }
        }
      }
    }
    return {}
  },
  created () {
    if (this.step) this.localStep = this.step
  },
  watch: {
    step (newStep) {
      this.localStep = newStep
    },
    userEmail (email) {
      this.confirmation.email = email
    }
  },
  computed: {
    confirmationEmail () {
      return this.userEmail ? this.userEmail : this.confirmation.email
    },
    /**
     * Check if emails inputs are equal
     */
    isEmailInputConfirmed () {
      return this.form.email === this.form.email_confirmation
    },
    /**
     * parse countries list
    */
    parsedCountries () {
      const countries = this.countries.map(item => {
        return {
          code: item,
          name: this.$t(`countries_names.${item}`)
        }
      })

      countries.sort((a, b) => a.name.localeCompare(b.name))

      return countries
    }
  },
  methods: {
    /**
     * Disable copy paste
     */
    disableCopyPaste () {
      // Prevent ctrl c + ctrl v
      this.$refs.authForm.addEventListener('copy', (event) => {
        event.clipboardData.setData('text/plain', '')
        event.preventDefault()
      })
    },
    /**
     * Remove disable copy and paste
     */
    removeDisableCopy () {
      // Remove listener to prevent ctrl + c
      this.$refs.authForm.removeEventListener('copy', this.disableCopyPaste)
    },
    /**
     * Resend code
     */
    async reSendCode () {
      this.isResendingCode = true
      this.content = null
      try {
        await AuthService.resendConfirmationCode({ email: this.confirmation.email })
        this.content_type = 'success'
        this.content = this.$t('info_new_code_2fa')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.isResendingCode = false
    },
    /**
     * Reset form
     */
    reset () {
      // this.form.name = ''
      // this.form.email = ''
      // this.form.document = ''
      // this.form.password = ''
      // this.form.password_confirmation = ''
      // this.form.phone = ''
      // this.form.type = ''
      // this.form.website = ''
      // this.form.category_id = ''
      // this.form.company_name = ''
      // this.form.company_document = ''
      // this.form.address.zipcode = ''
      // this.form.address.street = ''
      // this.form.address.number = ''
      // this.form.address.complement = ''
      // this.form.address.neighborhood = ''
      // this.form.address.city = ''
      // this.form.address.state = ''

      this.localStep = 'registration'
      this.$emit('closed-modal')
    },
    /**
     * On modal shown
     */
    shown () {
      if (this.step) this.localStep = this.step
      if (this.userEmail) this.confirmation.email = this.userEmail

      window.dataLayer.push({
        event: 'reg-init',
        event_category: 'open-modal',
        event_label: 'Cadastre-se agora!'
      })

      // Checks for referral code
      const code = window.localStorage.getItem('referral')
      if (code) {
        this.form.referral_code = code
      } else {
        this.form.referral_code = ''
      }

      // Disable copy paste
      this.disableCopyPaste()
    },
    /**
     * Submit registration form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const utmData = {
          utm_source: urlParams.get('utm_source') || null,
          utm_medium: urlParams.get('utm_medium') || null,
          utm_id: urlParams.get('utm_id') || null,
          utm_campaign: urlParams.get('utm_campaign') || null,
          utm_term: urlParams.get('utm_term') || null,
          utm_content: urlParams.get('utm_content') || null
        }
        const form = Object.assign({}, this.form, { utm_data: utmData })
        form.phone = form.phone.replace('+', '').replace(/\s/g, '')
        const response = await AuthService.register(form)
        this.confirmation.email = response.email
        this.localStep = 'verification'
        window.localStorage.removeItem('referral')
        window.dataLayer.push({
          event: 'reg-finish',
          event_category: 'form',
          event_label: 'Cadastrar'
        })
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
        document.getElementById('registration-modal').scrollTop = 0
      }
      this.loader = false
    },
    /**
     * Send verification code for validation
     */
    async verify () {
      this.loader = true
      this.content = null
      try {
        await AuthService.verifyEmail({
          code: this.confirmation.code,
          email: this.confirmationEmail
        })
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
        this.content_type = 'error'
      }
      this.loader = false
    }
  }
}
</script>
